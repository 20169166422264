import { combineReducers } from 'redux';

// Dynamic Loyalty reducers
import profileReducer from './profile';
import accountReducer from './account';
import knowledgeReducer from './knowledge';
import insightsReducer from './insights';
import modelReducer from './model';
import reportReducer from './report';

// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
    profile: profileReducer,
    account: accountReducer,
    knowledge: knowledgeReducer,
    insights: insightsReducer,
    model: modelReducer,
    report: reportReducer
});

export default reducer;
