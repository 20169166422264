/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React
import { useState } from "react";

// Animation
import { useTransition, animated as a } from "react-spring";

// react library for routing
import { Link } from "react-router-dom";
import { isMobile } from 'react-device-detect';

// reactstrap components
import { Button, Card, CardBody, Container,
         Modal, Form, FormGroup, Row, Col
} from "reactstrap";

// Hubspot
import HubspotForm from 'react-hubspot-form'

function IndexHeader() {
  // State
  const [loading, setLoading] = useState(true);
  const [contactModal, setContactModal] = useState(false);
  const [portfolioModal, setPortfolioModal] = useState(false);

  // Loading
  const transitions = useTransition(loading, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 1000 }
  });

  // Create container styles
  const ctaStyles = isMobile ? {
    position: 'absolute',
    bottom: 50,
    left: 20,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  } : {
    position: 'absolute',
    bottom: 50,
    left: 50
  };

  return (
    <>
      { transitions((style, i) => (
        <a.div style={style}>
          <Container style={ctaStyles}>
            <div className="header-body">
              <Row className="align-items-center">
                <Col xl="6">
                  <div className="pr-5">
                    <h1 className="display-2 text-white font-weight-bold mb-2">
                      Augmented Creativity
                    </h1>
                    <h2 className="display-5 text-white font-weight-light">
                      Avant-Garde is a venture studio exploring how AI will transform the creative economy.
                    </h2>
                    <div className={`mt-4${isMobile ? ' text-center' : ''}`}>
                      <Button
                        className="my-2"
                        color="default"
                        onClick={() => { setContactModal(true); }}
                      >
                        Contact Us
                      </Button>
                      <Button
                        className="my-2"
                        color="primary"
                        onClick={() => { setPortfolioModal(true); }}
                      >
                        View Portfolio
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col xl="6">

                </Col>
              </Row>
            </div>
          </Container>
          <Modal
            className="modal-dialog-centered"
            isOpen={contactModal}
            toggle={() => setContactModal(false)}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">
                Contact Us
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setContactModal(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <HubspotForm
               portalId='24024341'
               formId='d9547eb7-3b77-4e78-9dcd-63190b72dbc1'
               onSubmit={() => console.log('Submit!')}
               onReady={(form) => console.log('Form ready!')}
               loading={<div>Loading...</div>}
               />
            </div>
          </Modal>
          <Modal
            className="modal-dialog-centered"
            isOpen={portfolioModal}
            toggle={() => setPortfolioModal(false)}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">
                Arthaus
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setPortfolioModal(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col xl="12">
                  <div style={{
                    width: '100%'
                  }}>
                    <img
                      src="https://storage.googleapis.com/avant-garde-cdn/brands/arthaus/assets/v2/logo.square.png"
                      className="p-2 align-items-center "
                      style={{
                        width: '50%',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: 10
                      }}
                    ></img>
                  </div>
                </Col>
                <Col xl="12">
                  <h4 className="display-5 font-weight-light p-2">
                    Arthaus is an art marketplace that curates the finest high-quality framed prints from independent artists across the globe. Our mission is to provide artists with the tools and platform they need to succeed while providing art enthusiasts with an unmatched selection of one-of-a-kind artworks. By focusing on quality, innovation, and exceptional customer experiences, we aim to create a thriving community where both artists and customers are rewarded.
                  </h4>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <Button
                color="primary"
                href="#pablo"
                onClick={() => {
                  // Update contact
                  window.open("https://shop.myarthaus.com", "_self");
                }}
                size="xl"
              >
                Visit Homepage
              </Button>
            </div>
          </Modal>
        </a.div>
      ))}
    </>
  );
}

export default IndexHeader;
