
const config = {
  "account": {
    "contact": "admin@avant-garde.ai",
    "id": "avant-garde",
    "imageUrl": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/arthaus/assets/img/profile.png"
  },
  "assets": {
    "img": {
      "brand": {
        "backgrounds": {
          "profile": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/arthaus/assets/img/brand/backgrounds/profile.png"
        },
        "logo": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/arthaus/assets/img/brand/logo.svg",
        "logo-white": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/arthaus/assets/img/brand/logo-white.png"
      }
    }
  },
  "footer": {
    "company": {
      "link": "https://avant-garde.ai",
      "name": "Avant-Garde Capital, LLC"
    }
  },
  "theme": {
    "palette": {
      "color-1": "#1E3856",
      "color-2": "#353B40",
      "color-3": "#4B7EBA",
      "color-4": "#548DBB",
      "color-5": "#A28C62"
    }
  }
}

export default config;
