// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE';

// action - profile
export const INITIALIZE_PROFILE = '@profile/INITIALIZE_PROFILE';
export const CLEAR_PROFILE = '@profile/CLEAR_PROFILE';
export const UPDATE_PROFILE_USER = '@profile/UPDATE_PROFILE_USER';
export const UPDATE_WELCOME_STAGE = '@profile/UPDATE_WELCOME_STAGE';

// action - account
export const INITIALIZE_ACCOUNT = '@account/INITIALIZE_ACCOUNT';

// action - knowledge
export const INIITIALIZE_KNOWLEDGE = '@knowledge/INIITIALIZE_KNOWLEDGE';
export const SET_ACTIVE_DOMAIN = '@knowledge/SET_ACTIVE_DOMAIN';

// action - insights
export const INIITIALIZE_INSIGHTS = '@insights/INIITIALIZE_INSIGHTS';

// action - model
export const INIITIALIZE_MODEL = '@model/INIITIALIZE_MODEL';
export const SET_ACTIVE_ENTITY = '@model/SET_ACTIVE_ENTITY';

// action - report
export const INIITIALIZE_REPORT = '@report/INIITIALIZE_REPORT';
export const SET_ACTIVE_REPORT = '@report/SET_ACTIVE_REPORT';
export const SET_FILTER_TIMEFRAME = '@report/SET_FILTER_TIMEFRAME';
