// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,
    domain: {
      name: "Customers",
      id: "@axon/commerce/customers",
      display: "2,345 Total Customers"
    },
    entity: {
      name: "Persona 1",
      total: 100,
      value: "$2,500",
      strength: 80
    },
    stats: [
      {
        id: "@axon/commerce/customers/age",
        name: "18 - 25",
        value: 15,
        display: "Age: 18 - 25"
      },
      {
        id: "@axon/commerce/customers/age",
        name: "25 - 30",
        value: 30,
        display: "Age: 25 - 30"
      },
      {
        id: "@axon/commerce/customers/age",
        name: "30 - 45",
        value: 45,
        display: "Age: 30 - 45"
      },
      {
        id: "@axon/commerce/customers/age",
        name: "45 - 55",
        value: 10,
        display: "Age: 45 - 55"
      },
      {
        id: "@axon/commerce/customers/age",
        name: "55+",
        value: 5,
        display: "Age: 55+"
      }
    ],
    items: [
      {
        name: "Customer 1"
      },
      {
        name: "Customer 2"
      },
      {
        name: "Customer 3"
      },
      {
        name: "Customer 4"
      }
    ]
};

// ===========================|| PRODUCTS REDUCER ||=========================== //

const modelReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIITIALIZE_MODEL: {
          console.log("Initializing model...", action.payload);

          // Update state
          return {
              ...state,
              initialized: true
          };
        }
        case actionTypes.SET_ACTIVE_ENTITY: {
          console.log("Setting active entity...", action.payload);

          // Update state
          return {
              ...state,
              entity: action.payload
          };
        }
        default:
            return state;
    }
};

export default modelReducer;
