// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,
    config: {
      "accountId": "talaria-digital",
      "atlasUrl": "axon.talariadigital.com",
      "name": "Talaria Digital, Inc.",
      "version": "0.7.5"
    },
    clients: [
      {
        "build": "l5j77trd",
        "builds": {
          "dev": "l5j77trd",
          "local": "l5j77trd",
          "prod": "l5j77trd",
          "qa": "l5j77trd"
        },
        "domain": "teddystratford.com",
        "id": "teddystratford",
        "name": "Teddy Stratford",
        "progress": 0,
        "version": "1.0.0"
      }
    ]
};

// ===========================|| PRODUCTS REDUCER ||=========================== //

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE_ACCOUNT: {
          console.log("Initializing account...", action.payload);

          // Update state
          return {
              ...state,
              initialized: true
          };
        }
        default:
            return state;
    }
};

export default accountReducer;
