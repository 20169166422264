// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,

    // Domains
    domains: [
      {
        name: "Customers",
        id: "@axon/commerce/customers",
        display: "2,345 Total Customers"
      },
      {
        name: "Campaigns",
        id: "@axon/marketing/campaigns",
        display: "4 Total Campaigns"
      },
      {
        name: "Products",
        id: "@axon/commerce/products",
        display: "123 Total Products"
      },
      {
        name: "Orders",
        id: "@axon/commerce/orders",
        display: "543 Total Orders"
      },
      {
        name: "Places",
        id: "@axon/location/place",
        display: "434 Total Places"
      }
    ],
    selectedDomain: {
      name: "Customer Personas",
      id: "@axon/commerce/customers"
    },
    entities: [
      {
        id: "entity-1",
        name: "Persona 1",
        total: 100,
        value: "$2,500",
        strength: 80
      },
      {
        id: "entity-2",
        name: "Persona 2",
        total: 100,
        value: "$2,500",
        strength: 60
      },
      {
        id: "entity-3",
        name: "Persona 3",
        total: 100,
        value: "$2,500",
        strength: 30
      }
    ],
    groupings: []
};

// ===========================|| PRODUCTS REDUCER ||=========================== //

const knowledgeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIITIALIZE_KNOWLEDGE: {
          console.log("Initializing knowledge...", action.payload);

          // Update state
          return {
              ...state,
              initialized: true
          };
        }
        case actionTypes.SET_ACTIVE_DOMAIN: {
          console.log("Setting active domain...", action.payload);

          // Update state
          return {
              ...state,
              selectedDomain: action.payload
          };
        }
        default:
            return state;
    }
};

export default knowledgeReducer;
