// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,

    // Reports
    reports: [
      {
        query: "paid",
        name: "Paid",
        description: "Customers that were influenced by paid digital campaigns."
      },
      {
        query: "email",
        name: "Email",
        description: "Customers that engage with marketing emails."
      },
      {
        query: "organic",
        name: "Organic",
        description: "Customers coming from organic sources such as Google."
      },
      {
        query: "referral",
        name: "Referral",
        description: "Customers that were referred by external sources."
      },
      {
        query: "direct",
        name: "Direct",
        description: "Customers that visited the site directly on their own."
      }
    ],
    filters: {
      timeframe: {
        startDate: "2022-11-01",
        endDate: "2022-11-30"
      },
      clients: {
        list: [],
        selected: null
      }
    }

};

// ===========================|| PRODUCTS REDUCER ||=========================== //

const insightsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIITIALIZE_INSIGHTS: {
          console.log("Initializing insights...", action.payload);

          // Update state
          return {
              ...state,
              initialized: true,
              filters: action.payload.filters
          };
        }
        default:
            return state;
    }
};

export default insightsReducer;
